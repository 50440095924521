@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Circular-Std-Bold";
  src: url("./assets//Fonts/CircularStd/CircularStd-Bold.otf")
    format("opentype");
}

/* Font face for "Circular-Std-Book" */
@font-face {
  font-family: "Circular-Std-Book";
  src: url("./assets/Fonts/CircularStd/CircularStd-Book.otf") format("opentype");
}

/* Font face for "Circular-Std-Medium" */
@font-face {
  font-family: "Circular-Std-Medium";
  src: url("./assets/Fonts/CircularStd/CircularStd-Medium.otf")
    format("opentype");
}
input[type="radio"] {
  accent-color: #127e40;
}

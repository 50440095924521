/* Reset some default styling and set the font */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Circular-Std-Book", sans-serif;
  /*  */
}

/* Hide the default scrollbar for Webkit-based browsers */
*::-webkit-scrollbar {
  width: 0.4rem;
  height: 10px;
}
*::-webkit-scrollbar-thumb {
  background-color: #888;
}

/* Hide the default scrollbar for Firefox */
* {
  scrollbar-width: thin;
}
* {
  scrollbar-color: #888 transparent;
}

/* Smooth scrolling for anchor links and hide horizontal overflow */
#App {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.submitBtn {
  width: 90%;
  margin: auto;
  position: fixed;
  bottom: 2%;
  padding: 5px 6px;
}

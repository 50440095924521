.activeCardContainer {
  min-width: 18rem;
  width: 18rem;
  /* height: 24rem; */
  border-radius: 0.625rem;
  background: #f6efff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
}
.reserveCardContainer {
  background: #ffedec;
  min-width: 18rem;
  width: 18rem;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
}
.cardWrapper {
  margin: 1rem;
  /* border: 1px solid red; */
}
.cardWrapper > div {
  border-bottom: 1px solid #dadada;
  /* border: 1px solid red; */
}

.cardHeader > strong {
  font-size: 1rem;
}
.cardHeader > div {
  font-size: 0.875rem;
  color: #6b6b76;
}
.activeCardDetailsMain {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 5px;
}
.activeCardDetailsTitle {
  width: 100%;
  display: flex;
  padding-bottom: 1rem;
  gap: 0.5rem;
  align-items: flex-start;
}
.pickupLocationDU {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  /* border: 2px solid red; */
  padding: 0.5rem 0rem 0.5rem 0rem;
  /* height: 75px; */
}

.locationWrapperDU > div {
  width: 100%;
  color: #006d35;
  font-size: 0.625rem;
  line-height: 0.875rem;
}
.locationWrapperDU > strong {
  color: #191c19;
  font-size: 0.75rem;
  width: 100%;
}
.cardLayoutDU {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardLayoutDU > section {
  color: #191c19;
  text-align: right;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  width: 50%;
}
.cardLayoutDU > div {
  padding: 0.75rem 0rem 0.75rem 0rem;
  width: 50%;
  font-size: 0.875rem;
  color: #6b6b76;
  line-height: 0.875rem;
}

.packageBoxDate {
  border-radius: 0.2215rem;
  background: #ff6d6d;
  color: white;
  /* padding: 5px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
}
.dateBookBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0.75rem;
}
.packageWrapperDate {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
}

.ActiveCardLayoutDU {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ActiveCardLayoutDU > article > section {
  color: #191c19;
  text-align: right;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
}
.ActiveCardLayoutDU > article {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 5px;
}
.ActiveCardLayoutDU > div {
  padding: 0.75rem 0rem 0.75rem 0rem;
  width: 50%;
  font-size: 0.875rem;
  color: #6b6b76;
  line-height: 0.875rem;
}

.textMargin{
    margin-top: 10px;
    margin-bottom: 15px;
}
.givingPadding{
    padding-top: 10px;
}
.horizontalLine{
    color: rgba(0, 0, 0, 0.3);
}
.earningHistoryContainer {
    width: 97%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 10px;
    box-sizing: border-box;
    margin-left: 1px;
    font-size: 14px;
    font-weight: 600;
  }
  .earningHeadingStatus {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 0;
  }
  .earningHeadingStatus > p:first-child{
    display: flex;
    justify-content: center;
    align-items: center;
}
  .earningHeadingStatus > p:nth-child(2) {
    width: auto;
    padding: 2px 12px;
    border: 0;
    border-radius: 20px;
    color: white;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  .earningCollected {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10px ;
    padding-bottom: 10px;
  }
  .earningCredited {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10px;
  }
  .earningGurantee {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 10px 10px 10px;
  }
  .cricleInfo{
    color: black;
    font-weight: 600;
  }
  .react-multiple-carousel__arrow{
    background-color: rgba(0, 0, 0, 0.2) !important;
    top: 90%;
  }
  .carouselSubheadingPara{
    padding: 0 10px;
    margin-top : -22px;
    margin-bottom : 15px
  }

@media (max-width : 450px){
  .earningHeadingStatus > p:nth-child(2) {
    padding: 2px 9px;
    border-radius: 18px;
  }
  .react-multiple-carousel__arrow{
    background-color: rgba(0, 0, 0, 0.2) !important;
    top: 90%;
  }

}
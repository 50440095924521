/* Remove spin buttons in WebKit browsers (Safari, Chrome) */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  margin: 0;
}

/* Remove spin buttons in Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Remove spin buttons in Safari specifically */
input[type=number] {
  appearance: textfield;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

form {
  height: calc(var(--vh, 1vh) * 10);
  /* padding-bottom: 100px; */
}


@media only screen and (min-width: 320px) {
  form {
    padding-bottom: 300px;
  }
}

@media only screen and (min-width: 428px) {
  form {
    padding-bottom: 240px; 
  }
}

@media only screen and (min-width: 360px) {
  form {
    padding-bottom: 340px;
  }
}


@media only screen and (min-width: 411px) {
  form {
    padding-bottom: 370px;
  }
}


@media only screen and (min-width: 768px) {
  form {
    padding-bottom: 200px; 
  }
}
.packageDetailsContainer {
  border-radius: 0.3125rem;
  border: 1px solid #e6e6e8;
  background: #fff;
}
.packageDetailsContainer > section {
  color: #09081a;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.125rem;
  border-bottom: 1px solid #e6e6e8;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.activeCardDetailsMain {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.cardLayoutDU {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardLayoutDU > section {
  color: #191c19;
  text-align: right;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  width: 50%;
}
.cardLayoutDU > div {
  padding: 0.5rem 0rem 0.5rem 0rem;
  width: 50%;
  font-size: 0.875rem;
  color: #6b6b76;
  line-height: 0.875rem;
}
.packageBoxDate {
  border-radius: 0.2215rem;
  background: #009d61;
  color: white;
  /* padding: 5px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
}
.dateBookBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0.75rem;
}
.packageWrapperDate {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
}
.customerDetails {
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.mainPartnerDetails {
  padding: 0.75rem;
}
.customerDetails > span {
  color: #09081a;
  font-size: 0.75rem;
  line-height: 1.125rem; /* 128.571% */
  font-family: "Circular-Std-Bold";
  width: 95%;
}
.customerCall {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #191c19;
  text-align: right;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
}
.duBookDetailsBlock {
  display: flex;
  align-items: flex-start;
  column-gap: 0.5rem;
  padding: 0.25rem;
}

.reservation-container {
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
}
.reservation-button-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 85px;
  padding-top: 20px;
  padding-right: 16px;
  padding-left: 16px;
  background: white;
  border-radius: 5px 5px 0px 0px;
}
.reserve-button {
  width: 100%;
}
.fareText {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  padding: 0.5rem;
}
.fareText div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 700;
}

.fareText > ul > li {
  color: #61616c;
  font-size: 12px;
  font-weight: 450;
  line-height: 0.875rem;
}
